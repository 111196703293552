body {
	.container {
		margin: 5% 5%;
		border: 5px double white;
		border-width: 2px 0px;
		height: 0px;
		width: 88%;
		opacity: 0;
		padding: 0;
		margin-top: 30px;
		background-color: rgba(255, 255, 255, 0.123);
		-webkit-backdrop-filter: saturate(180%) blur(20px);
		backdrop-filter: saturate(180%) blur(20px);
	}

	.name {
		opacity: 0;
		color: white;
	}

	.App {
		width: 70%;
		margin: 0 auto;
	}
}

.open {
	transition: 0.5s;
	padding: 20px 0;
	margin: 0 auto;
	border-width: 2px 0px;
	height: 85px;
	width: 88%;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
	margin: 0 auto;
	.name {
		transition-duration: 0.5s;
		transition-delay: 0.3s;
		opacity: 1;
		color: white;
		text-shadow: 1px 5px 5px black;
		@media screen and (max-width: 500px) {
			margin-top: 0;
		}
	}
}

body {
	padding-top: 10%;
	background-size: auto;
	background-attachment: fixed;
	scroll-behavior: unset;
}

@supports (
	(-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
) {
	.open {
		background-color: rgba(255, 255, 255, .3);
		backdrop-filter: saturate(180%) blur(20px);
		-webkit-backdrop-filter: saturate(200%) blur(20px);
		border-radius: 10px;
	}
}
@supports not (
	(-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
) {
	.open {
		background-color: rgba(255, 255, 255, 0.4);
		border-radius: 10px;
	}
}
