// html {
// 	width: 100vw;
// 	height: 100%;
// 	box-sizing: border-box;
// 	text-align: center;
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	position: relative;
// }

// body {
// 	// background-image: url("./Assets/liquid.jpg");
// 	width: 100vw;
// 	min-height: 100%;
// 	box-sizing: border-box;
// 	text-align: center;
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	position: relative;
// }


body,
#root::before {
	background-image: url("./Assets/liquid.jpg");
	width: 100vw;
	min-height: 100vh;
	box-sizing: border-box;
	text-align: center;
	background-position: center;
	background-repeat: no-repeat;
	position: sticky;
}


