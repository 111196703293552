.nav {
	nav {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		color: white;
		padding: 5% 0;
		font-size: 1rem;
		opacity: 0;

		a {
			text-decoration: none;
			color: white;
			font-size: 1.5rem;
		}
	}
}

.classNameAfter {
	transition: 0.3s;
	position: fixed;
	top: 260px;
	width: 70.4vw;

	nav {
		transition-duration: 0.8s;
		transition-delay: 0.5s;
		padding: 5% 0;
		font-size: 1rem;
		display: flex;
		flex-direction: row;
		text-shadow: 1px 1.2px 0px grey;
		justify-content: space-between;
		a {
			color: white;
			font-size: 1.5rem;
			text-decoration: none;
			margin: 0 10px;
		}

		a:hover {
			color: rgba(255, 255, 254, 0.376);
			transition: 1s;
		}

		a:active {
			transition: 0.5s;
			text-shadow: 0px 0px 20px white;
		}
	}

	@media screen and (max-width: 500px) {
		top: 180px;
		margin: 0px auto;

		nav {
			justify-content: space-between;
		}

		nav a {
			font-size: 1rem;
			margin: 0 3px;
		}
	}
}

.afterHome {
	transition: 0.3s;
	position: fixed;
	top: 1%;
	width: 70.4vw;
}

.active {
	border-bottom: 1px solid white;
}

@supports (
	(-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
) {
	.Scrolling {
		background-color: rgba(255, 255, 255, .3);
		backdrop-filter: saturate(180%) blur(20px);
		-webkit-backdrop-filter: saturate(200%) blur(20px);
		transition: 0.5s !important;
		transition-delay: 0s !important;
		padding: 0 !important;
		border-radius: 10px;
	}
}
@supports not (
	(-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
) {
	.Scrolling {
		background-color: rgba(255, 255, 255, 0.7);
		transition: 0.5s !important;
		transition-delay: 0s !important;
		padding: 0 !important;
		border-radius: 10px;
		a {

			text-shadow:  2px 2px 2px black;
		}
	}
}
