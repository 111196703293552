.Work {
	color: white;
	background-color: rgba(255, 255, 255, 0.048);
	visibility: hidden;
	opacity: 0;
	height: 100%;
	visibility: visible;

	ul {
		display: -webkit-box;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		list-style-type: none;

		li {
			margin: 10px;
		}
	}
}

.WorkNxt {
	opacity: 1;
	-webkit-transition-duration: 1.5s;
	transition-duration: 1.5s;
	color: white;
	background-color: rgba(255, 255, 255, 0.048);
	height: 100%;
	visibility: visible;
	background: rgba(20, 2, 2, 0.246);
	margin: 0 auto;
	width: 88%;
	text-shadow: 1px 5px 5px black;

	.WorkInfo {
		height: 100%;
		padding: 1% 0;
	}

	.icon {
		position: fixed;
		left: 90%;
		top: 6%;
		height: 50px;
		width: 50px;
		color: white;

		@media screen and (max-width: 500px) {
			top: 0px;
			left: 87%;
			height: 40px;
			width: auto;
		}
	}

	.icon:hover {
		-webkit-transition: 0.3s;
		transition: 0.3s;
		color: #00b4dd;
	}
	a {
		text-decoration: none;
		h1 {
			color: white;
		}
	}
}
