.Contact {
	color: white;
	background-color: rgba(255, 255, 255, 0.048);
	visibility: hidden;
	opacity: 0;
	height: 100%;
	visibility: visible;
}

.ContactNxt {
	opacity: 1;
	-webkit-transition-duration: 1.5s;
	transition-duration: 1.5s;
	color: white;
	background-color: rgba(255, 255, 255, 0.048);
	height: 100%;
	visibility: visible;
	background: rgba(20, 2, 2, 0.246);
	margin: 0 auto;
	width: 88%;
	text-shadow: 1px 5px 5px black;

	.icon {
		position: fixed;
		left: 90%;
		top: 6%;
		height: 50px;
		width: 50px;
		color: white;

		@media screen and (max-width: 500px) {
			top: 0px;
			left: 87%;
			height: 40px;
			width: auto;
		}
	}

	.linkedin {
		color: #0077b5;
		background: white;
		height: 50px;
		width: auto;
		outline: 10px solid #0077b5;
		outline-offset: -10px;
	}

	.Twitter {
		color: white;
		height: 50px;
		width: auto;
		margin-left: 20px;
	}

	.icon:hover {
		-webkit-transition: 0.3s;
		transition: 0.3s;
		color: #00b4dd;
	}

	.ContactInfo {
		font-size: 1.5rem;
		h1 {
			margin: 0;
		}
		@media screen and (max-width: 500px) {
			font-size: 1rem;
		}
	}
}
